

































import { Turma } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { TurmaService } from "@/core/services/geral";
import {Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ConfiguracaoImpressaoChamada extends PageBase{
  
  turmas: Turma[] = [];
  turmaService: TurmaService = new TurmaService();
  isTurmaLoading: boolean = false;
  onSearchTurma: any = null;
  valid: boolean = true;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  
  filtro:any={
    turmaId: 0
  }

  $refs!: {
    form: HTMLFormElement
  }

  mounted() {
    this.turmaService.ListarAbertas().then(
      res=> {
        this.turmas = res.data.items
      }
    )
  }
    
  GerarRelatorio(){
    if (this.$refs.form.validate()) {
      let routeLink = this.$router.resolve({name:"impressaoChamada",
        query:{
          turmaId: this.filtro.turmaId,
        }
      });
      window.open(routeLink.href,'_blank')
    }
  }

  LimparFiltros(){
    this.filtro.turmaId = 0;
    this.$refs.form.resetValidation();
  }
      
  // @Watch('onSearchTurma')
  // SearchTurma (val:string){
  //   if(this.filtro.turmaId)
  //     return;
  //   if(this.isTurmaLoading)
  //     return;
  //   if(!val)
  //     return;
  //   this.isTurmaLoading = true
  //   this.turmaService.AutoComplete(val).then(
  //     res =>{
  //       this.turmas = res.data;
  //     },
  //     err => this.$swal('Aviso',err.response.data,'error')
  //   ).finally(() =>{
  //     this.isTurmaLoading = false;
  //   })
  // }
}

